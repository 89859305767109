require("jquery.cookie");

var breakpoint = 480;
var navTrigger = $("#navigation-trigger");
var navTriggerOpen = "btn--trigger-open";
var navList = $(".nav__list");

$(() => {

    if ($(window).width() < breakpoint) {
        $(navList).attr("hidden", "true");
    }
    else {
        $(navList).removeAttr("hidden");
    }

    $(navTrigger).on("click", (e: JQuery.Event) => {

        $(this).toggleClass(navTriggerOpen);

        if ($(navList).attr("hidden")) {

            $(navTrigger).addClass(navTriggerOpen);
            $(navList).removeAttr("hidden");
        } else {

            $(navTrigger).removeClass(navTriggerOpen);
            $(navList).attr("hidden", "");
        }
    });
});